import React from 'react';
import styled from 'styled-components';
import { Drawer, Typography, useTheme } from '@mui/material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DescriptionIcon from '@mui/icons-material/Description';
import { TMenu, TNewAside, TSubMenus } from './types.d';
import { MenuOptionV } from './MenuOption';

type TAside = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
  marginTop: string;
};

const Aside = styled.aside<TAside>`
  background: ${({ bgColor }) => bgColor || '#eee'};
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  height: 100%;
  padding-top: 2px;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  /* overflow-y: scroll; */
  overflow: auto;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(0px)')};
  transition:
    transform 0.3s ease-in-out,
    width 0.3s ease-in-out; /* Agrega una transición suave al transform y la anchura */
`;

type TMenuOptionLink = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
};

const MenuOptionLink = styled.div<TMenuOptionLink>`
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  /* height: 37px; */
  background: ${({ bgColor }) => bgColor || '#fff'};
  padding: 10px 15px 10px 25px;
  :hover {
    cursor: pointer;
    background: ${({ bgColor }) => bgColor || '#eee'};
  }
`;

// componente footer del aside
// const Footer = styled.div`
//   width: 100%;
//   height: 50px;
//   background: #fff;
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const SecondAsideTypeV: React.FC<TNewAside> = ({
  isOpen,
  data,
  headerType,
  drawerOpen,
  toggleAside,
  handleDrawerOpen,
  gradientAside,
  layoutType,
}: TNewAside) => {
  // estado que guarda los options de las categorías
  const [options, setOptions] = React.useState<any>([]);
  const theme = useTheme();

  // función que se ejecuta al hacer click en una categoría para seleccionar los items de la misma
  const handleOptions = (dataOptions: any) => {
    setOptions(dataOptions);
    // console.log(dataOptions);
    if (dataOptions?.name === options?.name || !drawerOpen) {
      handleDrawerOpen();
    }
  };

  const styles = {
    subTitle: {
      fontWeight: 'bold',
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: theme.palette.primary.main,
    },
    subTitleModule: {
      fontWeight: 'bold',
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: '#fff',
    },
    icon: {
      fontSize: isOpen ? '20px' : '24px',
      color: '#fff',
      marginTop: '5px',
    },
    iconDot: {
      fontSize: '14px',
      color: '#fff',
      marginTop: '5px',
    },
  };
  return (
    <Aside
      isOpen={isOpen}
      marginTop={headerType === 3 && layoutType !== 2 ? '5px' : '0px'}
      widthCollapsed="80px" /* Anchura del aside cuando está oculto */
      widthExpanded="270px" /* Anchura del aside cuando está visible */
      bgColor={
        gradientAside
          ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.dark}  45%, ${theme.palette.primary.main} 96%)`
          : theme.palette.primary.dark
      }>
      <div
        style={{
          display: 'flex',
          padding: '5px',
          flexDirection: 'column',
        }}>
        {data?.map((x: TMenu, indexX: number) => {
          const key = `keyX-${indexX}`;
          return (
            <MenuOptionV
              key={key}
              toggleAside={toggleAside}
              colorBg={
                gradientAside
                  ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.main}  45%, ${theme.palette.primary.main} 96%)`
                  : theme.palette.primary.main
              }
              textColor="#fff"
              title={isOpen ? x?.name : ''}
              titlePopper={x?.name}
              isOpen={isOpen}
              icon={
                (x?.name === 'CONTABILIDAD' && <RecentActorsIcon style={styles.icon} />) ||
                (x?.name === 'TALENTO HUMANO' && <DescriptionIcon style={styles.icon} />)
              }>
              {x?.subMenus && x?.subMenus?.length > 0
                ? x?.subMenus?.map((y: TSubMenus, indexY: number) => {
                    const keySubModule = `keyY-${indexY}`;
                    return y?.categories?.length ? (
                      <MenuOptionLink
                        key={keySubModule}
                        onClick={() => handleOptions(y)}
                        bgColor={options?.name === y.name ? '#dcdcdc' : '#fff'}>
                        <Typography sx={styles.subTitle}>{y?.name}</Typography>
                      </MenuOptionLink>
                    ) : (
                      <MenuOptionLink bgColor={theme.palette.primary.light} key={keySubModule}>
                        <Typography sx={styles.subTitle}>{y?.name}</Typography>
                      </MenuOptionLink>
                    );
                  })
                : null}
            </MenuOptionV>
          );
        })}
      </div>
      <Drawer
        open={drawerOpen}
        onClose={() => handleDrawerOpen()}
        sx={{
          width: '270px',
          flexShrink: 0,
          left: '270px',
          '& .MuiDrawer-paper': {
            left: '270px',
            width: '270px',
            top: '100px',
            zIndex: 2,
            backgroundColor: '#fff',
          },
        }}>
        <div style={{ padding: '5px', height: 'calc(100% - 115px)', overflow: 'auto' }}>
          {options?.categories?.length > 0 ? (
            options?.categories?.map((x: any, index: number) => {
              const key = `keyX-${index}`;
              return (
                <MenuOptionV
                  key={key}
                  toggleAside={toggleAside}
                  colorBg={
                    gradientAside
                      ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.main}  45%, ${theme.palette.primary.main} 96%)`
                      : theme.palette.primary.main
                  }
                  textColor="#fff"
                  title={isOpen ? x?.name : ''}
                  titlePopper={x?.name}
                  isOpen={isOpen}
                  icon={<RecentActorsIcon style={styles.icon} />}>
                  {x?.options && x?.options?.length > 0
                    ? x?.options?.map((y: any, indexY: number) => {
                        const keySubModule = `keyY-${indexY}`;
                        return (
                          <MenuOptionLink key={keySubModule} onClick={() => handleOptions(y)}>
                            <Typography sx={styles.subTitle}>{y?.name}</Typography>
                          </MenuOptionLink>
                        );
                      })
                    : null}
                </MenuOptionV>
              );
            })
          ) : (
            <div>b</div>
          )}
        </div>
      </Drawer>
    </Aside>
  );
};
