import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import SmartButtonIcon from '@mui/icons-material/SmartButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import RemoveIcon from '@mui/icons-material/Remove';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useMutation } from '@apollo/client';
import { DropdownHeaderV } from './DropdownHeader';
import image from '../../../infrastructure/common/images';
import { ColorPaletteTooltip } from './ColorPaletteTooltip';
import { CHANGE_PASS_USER } from '../../containers/Layout/queries';

type THeader = {
  height?: string;
  bgColor?: string;
  headerType?: number;
};

// componente de styled component para el header del layout
const Header = styled.header<THeader>`
  grid-area: header;
  background-color: #fff;
  color: #171717;
  display: flex;
  height: ${({ headerType }) =>
    headerType === 1 ? '100px' : `${headerType === 3 ? '60px' : '80px'}`};
  justify-content: center;
  align-items: center;
  /* z-index: 1700; */
`;

const SecondHeader = styled.header`
  height: 65px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 5px 0px #3939392b;
  z-index: 1;
`;

const ThirdHeader = styled.header`
  height: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 5px 0px #3939392b;
  z-index: 1;
`;

// Amoldando medidas del logo
export const LogoImage = styled.img`
  width: 100%;
  height: 60%;
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`;

export const NewHeader: FC<any> = ({
  loggedUser,
  isOpen,
  toggleAside,
  headerType,
  closeAllExpansion,
  handleSession,
  gradientHeader,
  colorHeader,
}) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState({ changePassword: false });
  const theme = useTheme();
  const LogoImgMili = image('logo');
  const [updatePassword, { loading: loadingUpdatePassword }] = useMutation(CHANGE_PASS_USER);

  const handleMouseEnter = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const sendConfirm = (modal: string) => {
    switch (modal) {
      case 'changePassword':
        setOpenModal({ ...openModal, changePassword: !openModal?.changePassword });
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const schema = yup
    .object({
      newPassword: yup
        .string()
        .matches(/^[a-zA-Z0-9\s]*$/gm, 'Campo alfanumérico')
        .required('Campo requerido'),
      confirmPassword: yup
        .string()
        .matches(/^[a-zA-Z0-9\s]*$/gm, 'Campo alfanumérico')
        .required('Campo requerido')
        .oneOf([yup.ref('newPassword'), ''], 'Las contraseñas deben ser iguales'),
    })
    .required();

  const { control, watch, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const newPassword = watch('newPassword');
  const uId = window.localStorage.getItem('uId');

  const handleUpdatePassword = async () => {
    const response = await updatePassword({
      variables: {
        input: {
          uId,
          uPass: newPassword,
        },
      },
    });
    if (response) {
      sendConfirm('changePassword');
      reset({
        newPassword: '',
        confirmPassword: '',
      });
      toast('Contraseña actualizada con éxito', {
        autoClose: 3000,
        type: 'success',
        hideProgressBar: true,
      });
    }
  };
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string, location?: string) => {
    return {
      sx: {
        bgcolor: location === 'header' ? '#fff' : stringToColor(name),
        color: location === 'header' ? theme.palette.primary.main : '#fff',
        fontSize: location === 'header' ? '0.85rem' : '1.25',
        width: location === 'header' ? '25px' : '45px',
        height: location === 'header' ? '25px' : '45px',
        marginTop: location === 'header' && '0',
        fontWeight: 'bold',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  const styles = {
    grow: {
      flexGrow: 1,
      backgroundColor: '#ffffff',
      position: 'sticky',
    },
    sectionDesktop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      margin: headerType === 1 ? '6px' : `${headerType === 3 ? '65px 0 0 10px' : '30px 0 0 10px'}`,
    },
    content: {
      display: 'flex',
      with: '50%',
      //   justifyContent: 'space-between',
      minHeight: '30px',
      height: headerType === 3 ? '30px' : '48px',
      paddingLeft: '10px',
    },
    logo: {
      width: '38%',
    },
    logoLg: {
      display: 'block',
    },
  };

  return (
    <Header headerType={headerType}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <AppBar
          sx={{
            height: headerType === 1 ? '45px' : `${headerType === 3 ? '80px' : '80px'}`,
            background:
              headerType === 1
                ? `${
                    gradientHeader
                      ? `linear-gradient(150deg, ${theme.palette.secondary.dark}  0%, ${theme.palette.secondary.main}  45%, ${theme.palette.secondary.main} 96%)`
                      : theme.palette.secondary.main
                  }`
                : `${
                    colorHeader
                      ? `${
                          gradientHeader
                            ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.main}  45%, ${theme.palette.primary.main} 96%)`
                            : theme.palette.primary.main
                        }`
                      : '#fff'
                  }`,
            position: 'inherit',
            marginBottom: 'auto',
          }}>
          <Toolbar style={styles.content}>
            {headerType === 1 ? (
              <Typography variant="h6" sx={{ color: '#fff' }}>
                COOPHUMANA
              </Typography>
            ) : (
              <>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Button
                    sx={{
                      width: '30px',
                      height: '30px',
                      minWidth: '30px',
                      margin: headerType === 3 ? '65px 0 0 10px' : '30px 0 0 10px',
                      // backgroundColor: '#def7f2',
                      borderRadius: '5px',
                      backgroundColor: 'none',
                    }}
                    onClick={() => toggleAside()}>
                    <MenuIcon
                      sx={{
                        borderRadius: '5px',
                        color: colorHeader ? '#fff' : theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: colorHeader ? theme.palette.primary.main : '#def7f2',
                        },
                      }}
                    />
                  </Button>
                </div>
                <div
                  style={{
                    width: isOpen ? 'calc(100% - 285px)' : '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: headerType === 3 ? '70px 0 0 0' : '30px 0 0 10px',
                    //   boxShadow: '5px 3px 3px 0px #dcdcdc',
                    zIndex: 999,
                  }}>
                  <Link to="/">
                    <HeaderContainer>
                      <LogoImage src={LogoImgMili} alt="exampleLogo" />
                    </HeaderContainer>
                  </Link>
                </div>
              </>
            )}
            <Box sx={styles.sectionDesktop}>
              {window.location.pathname === '/' ? <ColorPaletteTooltip /> : null}
              <IconButton
                aria-label="show 4 new mails"
                color={headerType && headerType === 1 ? 'inherit' : 'primary'}>
                {/* <Badge badgeContent={6} color="secondary"> */}
                <Badge variant="dot">
                  <MailIcon
                    sx={{
                      color:
                        colorHeader && (headerType !== 2 || headerType !== 3)
                          ? '#fff'
                          : theme.palette.primary.main,
                    }}
                  />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="show 17 new notifications"
                color={headerType === 1 ? 'inherit' : 'primary'}>
                <Badge variant="dot">
                  <NotificationsIcon
                    sx={{
                      color:
                        colorHeader && (headerType !== 2 || headerType !== 3)
                          ? '#fff'
                          : theme.palette.primary.main,
                    }}
                  />
                </Badge>
              </IconButton>
              <DropdownHeaderV
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                open={open}
                headerType={headerType}
                anchorEl={anchorEl}
                stringAvatar={stringAvatar}
                handleSession={handleSession}
                openModal={openModal}
                sendConfirm={sendConfirm}
                setOpenModal={setOpenModal}
                control={control}
                schema={schema}
                handleSubmit={handleSubmit}
                handleUpdatePassword={handleUpdatePassword}
                loadingUpdatePassword={loadingUpdatePassword}
              />
            </Box>
          </Toolbar>
          {/* <Box sx={styles.grow} /> */}
        </AppBar>
        {headerType === 1 && (
          <SecondHeader>
            <Button
              sx={{
                width: '30px',
                height: '30px',
                margin: '10px 0 0 10px',
                // backgroundColor: '#def7f2',
                borderRadius: '5px',
                minWidth: '30px',
                backgroundColor: 'none',
              }}
              onClick={() => toggleAside()}>
              <MenuIcon color={!colorHeader ? 'inherit' : 'primary'} />
            </Button>
            <div
              style={{
                width: isOpen ? 'calc(100% - 285px)' : '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 40px 0 0',
                //   boxShadow: '5px 3px 3px 0px #dcdcdc',
                zIndex: 999,
              }}>
              <Link to="/">
                <HeaderContainer>
                  <LogoImage src={LogoImgMili} alt="exampleLogo" />
                </HeaderContainer>
              </Link>
            </div>
          </SecondHeader>
        )}
        {headerType === 3 && (
          <ThirdHeader>
            <div
              style={{
                display: isOpen ? 'flex' : 'none',
                width: isOpen ? '240px' : '80px',
                justifyContent: 'flex-end',
              }}>
              <Tooltip title="Contraer todo" placement="right">
                <Button
                  sx={{
                    width: '15px',
                    height: '14px',
                    zIndex: 9999999999999,
                    // backgroundColor: '#def7f2',
                    borderRadius: '5px',
                    marginBottom: '12px',
                    minWidth: '10px',
                  }}
                  onClick={() => closeAllExpansion()}>
                  <RemoveIcon
                    color={!colorHeader ? 'inherit' : 'primary'}
                    sx={{ fontSize: '24px' }}
                  />
                </Button>
              </Tooltip>
              {/* <Tooltip title="Mostrar ruta de posición" placement="bottom">
                <Button
                  sx={{
                    width: '15px',
                    height: '14px',
                    // backgroundColor: '#def7f2',
                    borderRadius: '5px',
                    marginBottom: '12px',
                    minWidth: '10px',
                  }}
                  onClick={() => closeAllExpansion()}>
                  <VisibilityIcon
                    color={!colorHeader ? 'inherit' : 'primary'}
                    sx={{ fontSize: '18px' }}
                  />
                </Button>
              </Tooltip> */}
            </div>
          </ThirdHeader>
        )}
      </div>
    </Header>
  );
};
