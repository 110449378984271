import { useState, useEffect, useCallback } from 'react';

type TGetWindowDimensions = {
  width: number | null;
  height: number | null;
};

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback((): TGetWindowDimensions => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleResize = useCallback(
    () => setWindowDimensions(getWindowDimensions()),
    [getWindowDimensions]
  );

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [hasWindow, getWindowDimensions, handleResize]);

  const w = (percent: number) => {
    const value = (window.innerWidth * percent) / 100;
    return !Number(value) ? '0px' : `${value}px`;
  };

  const h = (percent: number) => {
    const value = (window.innerHeight * percent) / 100;
    return !Number(value) ? '0px' : `${value}px`;
  };

  return { windowDimensions, w, h };
}
