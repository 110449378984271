import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import Yup from 'yup';

// @mui/material TextField adapter component for react-hook-form and yup validation schema

export const CheckBoxAdapter = (
  props: CheckboxProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    label?: string;
    check?: boolean;
    checked?: boolean;
  }
) => {
  const { name, control, schema, label, sx, ...rest } = props;
  const theme = useTheme();
  const { field } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  return (
    <FormControlLabel
      sx={sx || { m: theme.spacing(1) }}
      label={label || ''}
      control={<Checkbox {...rest} {...field} name={name} />}
    />
  );
};
