export const colorsToSelect = [
  {
    primary: { main: '#311b92', dark: '#1d1056', light: '#d2ddee' },
    secondary: { main: '#ec6a32', dark: '#e05215', light: '#f5aa8e' },
    info: { main: '#f59121', dark: '#c63703', light: '#ffc255' },
  },
  {
    primary: { main: '#1C6301', dark: '#143802', light: '#D9F0CE' },
    secondary: { main: '#FF6800', dark: '#FF4400', light: '#ffc255' },
    info: { main: '#f59121', dark: '#c63703', light: '#ffc255' },
  },
  {
    primary: { main: '#FBBD23', dark: '#e07000', light: '#fff0c2' },
    secondary: { main: '#9c6d7c', dark: '#8a6a71', light: '#d5c3c9' },
    info: { main: '#9c6d7c', dark: '#8a6a71', light: '#d5c3c9' },
  },
  {
    primary: { main: '#744f44', dark: '#34211d', light: '#eee5e9' },
    secondary: { main: '#30c587', dark: '#568a7b', light: '#bfedda' },
    info: { main: '#30c587', dark: '#568a7b', light: '#bfedda' },
  },
  {
    primary: { main: '#00cc69', dark: '#008f3c', light: '#edfdf1' },
    secondary: { main: '#898085', dark: '#5e565a', light: '#e2dfe0' },
    info: { main: '#898085', dark: '#5e565a', light: '#e2dfe0' },
  },
  {
    primary: { main: '#00b8cc', dark: '#006a7a', light: '#ebffff' },
    secondary: { main: '#81747d', dark: '#4b4449', light: '#e2dfe1' },
    info: { main: '#81747d', dark: '#4b4449', light: '#e2dfe1' },
  },
  {
    primary: { main: '#12ba90', dark: '#007a6a', light: '#d6fff5' },
    secondary: { main: '#494573', dark: '#241b37', light: '#d0cede' },
    info: { main: '#494573', dark: '#241b37', light: '#d0cede' },
  },
  {
    primary: { main: '#001e52', dark: '#000b29', light: '#daecfb' },
    secondary: { main: '#787368', dark: '#38342e', light: '#ecebea' },
    info: { main: '#787368', dark: '#38342e', light: '#ecebea' },
  },
  {
    primary: { main: '#0C5697', dark: '#052039', light: '#c8e2f9' },
    secondary: { main: '#8d5f01', dark: '#794501', light: '#f7e9ca' },
    info: { main: '#8d5f01', dark: '#794501', light: '#f7e9ca' },
  },
  {
    primary: { main: '#00665a', dark: '#003d36', light: '#c3e9e9' },
    secondary: { main: '#bf714a', dark: '#79452a', light: '#ead0c3' },
    info: { main: '#bf714a', dark: '#79452a', light: '#ead0c3' },
  },
  {
    primary: { main: '#a30021', dark: '#660014', light: '#E1CBCF' },
    secondary: { main: '#035e63', dark: '#012628', light: '#aceaec' },
    info: { main: '#035e63', dark: '#012628', light: '#aceaec' },
  },
  {
    primary: { main: '#710a38', dark: '#250312', light: '#fcd9e9' },
    secondary: { main: '#91a975', dark: '#507444', light: '#d7e0cd' },
    info: { main: '#91a975', dark: '#507444', light: '#d7e0cd' },
  },
  {
    primary: { main: '#6d00a3', dark: '#360052', light: '#ebc2ff' },
    secondary: { main: '#d78f09', dark: '#9d6807', light: '#f7e6c9' },
    info: { main: '#d78f09', dark: '#9d6807', light: '#f7e6c9' },
  },
  {
    primary: { main: '#90b800', dark: '#607a00', light: '#f2f4cd' },
    secondary: { main: '#2d3047', dark: '#181925', light: '#d1d3db' },
    info: { main: '#2d3047', dark: '#181925', light: '#d1d3db' },
  },
  {
    primary: { main: '#424242', dark: '#1F1F1F', light: '#e0e0e0' },
    secondary: { main: '#e98a15', dark: '#bb6e11', light: '#f5c78f' },
    info: { main: '#e98a15', dark: '#bb6e11', light: '#f5c78f' },
  },
  {
    primary: { main: '#222C30', dark: '#111618', light: '#94AAB3' },
    secondary: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#222C30', dark: '#111618', light: '#94AAB3' },
    secondary: { main: '#17bee7', dark: '#1790B5', light: '#DDF0F8' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#222C30', dark: '#111618', light: '#94AAB3' },
    secondary: { main: '#0095DA', dark: '#00628F', light: '#ADE5FF' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#172A8E', dark: '#0E1A58', light: '#DCE2F9' },
    secondary: { main: '#F3CD0C', dark: '#FF9D0A', light: '#FBEFB1' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#0097D7', dark: '#00648F', light: '#D6F3FF' },
    secondary: { main: '#FFB23F', dark: '#F59121', light: '#FFE7C2' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#0071C0', dark: '#00477A', light: '#D6EEFF' },
    secondary: { main: '#FF6F3B', dark: '#FF4400', light: '#FFD2C2' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#FFCE47', dark: '#E0A500', light: '#FFEFC2' },
    secondary: { main: '#101C42', dark: '#080E21', light: '#CED4F3' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#006BB6', dark: '#00477A', light: '#C2E5FF' },
    secondary: { main: '#FFB23F', dark: '#F59300', light: '#FFDEAD' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#2DAC67', dark: '#1D7243', light: '#CEF3DD' },
    secondary: { main: '#1571BA', dark: '#105893', light: '#C8E3F9' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#4147AF', dark: '#272A68', light: '#D2D4EE' },
    secondary: { main: '#FFB23F', dark: '#F59121', light: '#FFD699' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#0B2442', dark: '#061323', light: '#DCE9F9' },
    secondary: { main: '#FFB23F', dark: '#CC7A00', light: '#FFD699' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#56AC47', dark: '#206F29', light: '#E5F3E2' },
    secondary: { main: '#1571BA', dark: '#0E4D81', light: '#C8E2F9' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#BDCC61', dark: '#94A528', light: '#EBF0D1' },
    secondary: { main: '#004A64', dark: '#002D3D', light: '#D6F4FF' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
  {
    primary: { main: '#014C2D', dark: '#012818', light: '#D7FEEE' },
    secondary: { main: '#32FAA5', dark: '#049F5F', light: '#C3FDE5' },
    info: { main: '#AF441D', dark: '#601E06', light: '#F1CABC' },
  },
];
