import { Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { TChangePassword } from './types.d';
import { InputField } from '../../../infrastructure/common/formAdapters';

export const ChangePasswordV: React.FC<TChangePassword> = ({
  control,
  schema,
  handleSubmit,
  handleUpdatePassword,
  loadingUpdatePassword,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      fontSize: '1em',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: `${theme.palette.primary.dark}`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      background: theme.palette.secondary.main,
      height: '30px',
      width: 'auto',
      margin: '0 10px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
    buttonText: {
      fontSize: '0.8em',
      fontWeight: 'bold',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
  return (
    <Box>
      <Typography sx={styles.header}>Cambiar contraseña</Typography>
      <Box style={{ maxHeight: '300px', overflow: 'auto', padding: '20px' }}>
        <Box key="" style={{ display: 'flex' }}>
          <Grid container rowSpacing={1} spacing={2} justifyContent="flex-start">
            <Grid item xs={12} md={6}>
              <InputField
                name="newPassword"
                control={control}
                type="password"
                label="Nueva contraseña *"
                schema={schema.fields.newPassword}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="confirmPassword"
                control={control}
                type="password"
                label="Confirmar contraseña *"
                schema={schema.fields.confirmPassword}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
          <Button sx={styles.button}>
            <Typography sx={styles.buttonText} onClick={handleSubmit(handleUpdatePassword)}>
              {loadingUpdatePassword ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                'ACTUALIZAR CONTRASEÑA'
              )}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
