import React, { ChangeEvent } from 'react';
import { SelectChangeEvent, TextField, TextFieldProps } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import Yup from 'yup';

const CustomTextField = styled(TextField)(({ theme: any }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '1em',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.9em',
    paddingBottom: '3px',
  },
  '& .MuiInputBase-root': {
    height: '38px',
    paddingBottom: '3px',
  },
}));

// @mui/material TextField adapter component for react-hook-form and yup validation schema
export const InputAdapter = (
  props: TextFieldProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    onBlur?: any;
    customOnChange?: any;
  }
) => {
  const { name, control, schema, defaultValue, customOnChange, onBlur, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    field: { onChange, value, ref },
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>
  ) => {
    onChange(e);
    if (customOnChange) customOnChange(e);
  };

  return (
    <CustomTextField
      {...rest}
      name={name}
      inputRef={ref}
      value={defaultValue || value}
      onChange={(e) => (customOnChange ? handleChange(e) : onChange(e))}
      onBlur={onBlur}
      size="small"
      error={rest?.disabled ? false : invalid}
      helperText={rest?.disabled ? '' : t(err?.message || '')}
      sx={{ mb: theme.spacing(1), width: '100%' }}
    />
  );
};
