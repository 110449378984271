import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import Yup from 'yup';

const CustomTextField = styled(TextField)(({ theme: any }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '0.9em',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.9em',
  },
  '& .MuiInputBase-root': {
    height: '38px',
  },
}));

// @mui/material TextField adapter component for react-hook-form and yup validation schema
export const InputDateAdapter = (
  props: TextFieldProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
  }
) => {
  const { name, control, schema, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  return (
    <CustomTextField
      {...rest}
      name={name}
      type="date"
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      size="small"
      error={invalid}
      helperText={t(err?.message || '')}
      InputLabelProps={{ shrink: true }}
      sx={{ mb: theme.spacing(2), width: '100%' }}
    />
  );
};
