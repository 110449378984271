import React from 'react';
import { Box, ClickAwayListener, styled, Typography, useTheme, Button } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import PaletteIcon from '@mui/icons-material/Palette';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { colorsToSelect } from './DefaultColors';

// import { Scrollbar } from './styled';

export const ColorPaletteTooltip: React.FC = () => {
  const theme = useTheme();

  const [openColorEdit, setOpenColorEdit] = React.useState(false);

  const openTooltipColor = () => {
    setOpenColorEdit(!openColorEdit);
  };
  // const theme = useTheme();
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      zIndex: 3000,
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #E6E8ED',
      },
      margin: '0 0 -10px 0',
      zIndex: 3000,
      color: '#fff',
    },
  }));

  const saveItemsLocalStorage = (colors: { main: string; dark: string; light: string }) => {
    const finalColors = { ...colors, gradients: { headerGradient: true, modulesGradient: true } };
    window.localStorage.setItem('colorsTheme', JSON.stringify(finalColors));
    window.location.reload();
  };
  const removeColorStorageTheme = () => {
    window.localStorage.removeItem('colorsTheme');
    window.location.reload();
  };
  return (
    <div>
      <ClickAwayListener onClickAway={openTooltipColor}>
        <LightTooltip
          sx={{ inset: '-5px 0 0 0' }}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={openTooltipColor}
          open={openColorEdit}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          arrow
          title={
            <Box
              sx={{
                width: '280px',
                height: '480px',
                zIndex: 30000,
                backgroundColor: '#fff',
              }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1em',
                  margin: '3px 0',
                  textAlign: 'left',
                  color: `${theme.palette.primary.main}`,
                }}>
                Seleccione la paleta de colores de preferencia:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignSelf: 'center',
                }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1em',
                    width: '52%',
                    textAlign: 'center',
                    color: `${theme.palette.primary.main}`,
                  }}>
                  Color primario
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    width: '50%',
                    fontSize: '1em',
                    textAlign: 'left',
                    color: `${theme.palette.primary.main}`,
                  }}>
                  Color secundario
                </Typography>
              </Box>
              <Box sx={{ height: '345px', overflow: 'auto' }}>
                {colorsToSelect
                  ? colorsToSelect?.map((x: any, index: number) => {
                      const key = `key-${index}`;
                      return (
                        <Box
                          key={key}
                          sx={{
                            display: 'flex',
                            padding: '2px',
                            margin: '10px',
                            flexDirection: 'row',
                            borderRadius: '5px',
                            ':hover': {
                              cursor: 'pointer',
                              outlineColor: theme.palette.primary.main,
                              outlineStyle: 'solid',
                            },
                          }}
                          onClick={() => saveItemsLocalStorage(x)}>
                          <Box
                            sx={{
                              marginRight: '10px',
                              display: 'flex',
                              flexDirection: 'row',
                            }}>
                            <Box
                              sx={{
                                backgroundColor: x?.primary?.dark,
                                width: '38px',
                                height: '38px',
                                borderTopLeftRadius: '5px',
                                borderBottomLeftRadius: '5px',
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: x?.primary?.main,
                                width: '38px',
                                height: '38px',
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: x?.primary?.light,
                                width: '38px',
                                height: '38px',
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px',
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}>
                            <Box
                              sx={{
                                backgroundColor: x?.secondary?.dark,
                                width: '38px',
                                height: '38px',
                                borderTopLeftRadius: '5px',
                                borderBottomLeftRadius: '5px',
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: x?.secondary?.main,
                                width: '38px',
                                height: '38px',
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: x?.secondary?.light,
                                width: '38px',
                                height: '38px',
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px',
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })
                  : null}
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button
                  onClick={() => removeColorStorageTheme()}
                  sx={{
                    width: '40px',
                    height: '50px',
                    margin: '15px 10px 15px 0',
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                    ':hover': {
                      backgroundColor: theme.palette.primary.dark,
                      // cursor: 'pointer',
                      // outline: 'solid',
                    },
                  }}>
                  <SettingsIcon />
                </Button>
                <Button
                  onClick={() => removeColorStorageTheme()}
                  sx={{
                    width: '220px',
                    height: '50px',
                    marginTop: '15px',
                    fontSize: '1em',
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                    ':hover': {
                      backgroundColor: theme.palette.primary.dark,
                      // cursor: 'pointer',
                      // outline: 'solid',
                    },
                  }}>
                  Restablecer color original
                </Button>
              </Box>
            </Box>
          }>
          <IconButton color="inherit" onClick={openTooltipColor}>
            <PaletteIcon
              sx={{
                color: '#fff',
              }}
            />
          </IconButton>
        </LightTooltip>
      </ClickAwayListener>
    </div>
  );
};
