import { gql } from '@apollo/client';

export const GET_USER_ADMIN_MENU = gql`
  query adminUserMenus($uId: ID, $mId: ID, $state: [Int], $order: [[String]], $limit: [Int]) {
    adminUserMenus(uId: $uId, mId: $mId, state: $state, order: $order, limit: $limit) {
      aumId
      mId
      menu {
        mId
        mName
        mCode
      }
      adminUserSubMenus {
        ausmId
        smId
        subMenu {
          smId
          smName
          smCode
        }
      }
      aumState
    }
  }
`;

export const CHANGE_PASS_USER = gql`
  mutation changePassUser($input: IChangePassUser!) {
    changePassUser(input: $input) {
      uId
    }
  }
`;

export const GET_ALL_MENUS = gql`
  query Menus($state: [Int]) {
    menus {
      mCode
      mId
      mName
      mPriority
      mState
      subMenus(state: $state) {
        smId
        mId
        smCode
        smName
        smPriority
        smState
        smModule
      }
    }
  }
`;

export const DECODED_TOKEN = gql`
  query DecodeUserToken($token: String) {
    decodeUserToken(token: $token) {
      uId
      uState
      ucId
      tokenAuth
      subMenus {
        smId
        mId
        smCode
        smName
        smPriority
        smState
        smModule
      }
    }
  }
`;
