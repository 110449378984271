import React from 'react';
import styled, { keyframes } from 'styled-components';
import images from '../images/index';

const animationImg = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.4; }
    100% { opacity: 1; }
`;
const Logo = styled.img`
  width: 200px;
  height: 200px;
  animation: ${animationImg} 2s infinite;
`;

const Loading = ({ url }: { url?: string }) => {
  const LoadingImg = url ?? images('logoM');
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F1F1F1',
        height: '100vh',
      }}>
      <Logo src={LoadingImg} />
    </div>
  );
};

export default Loading;
