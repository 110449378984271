import React, { ChangeEvent } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import Yup from 'yup';

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-root': {
    minHeight: '38px', // Altura del select
    fontSize: '0.9em', // Tamaño del texto seleccionado
    height: '38px', // Altura del select
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.9em', // Tamaño del texto del label
  },
}));

// @mui/material TextField adapter component for react-hook-form and yup validation schema
type TSelectOptions = {
  value?: string | number;
  label: string;
};
export const SelectAdapter = (
  props: SelectProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    options: TSelectOptions[];
    customOnChange?: () => void;
  }
) => {
  const { name, control, schema, options, customOnChange, ...rest } = props;
  const theme = useTheme();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<any>) => {
    onChange(e);
    if (customOnChange) customOnChange();
  };

  return (
    <FormControl size="small" sx={{ mb: theme.spacing(1), width: '100%' }}>
      <InputLabel error={!!err} sx={{ fontSize: '1em' }}>
        {rest.label}
      </InputLabel>
      <CustomSelect
        {...rest}
        sx={{ minHeight: '38px', height: '38px' }}
        name={name}
        inputRef={ref}
        value={value}
        onChange={(e) => (customOnChange ? handleChange(e) : onChange(e))}
        onBlur={onBlur}
        error={!!err}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          sx: {
            maxHeight: '290px',
            width: '100px',
            '& li': {
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
              fontSize: '0.8em',
            },
          },
        }}>
        {options.map((option, index) => {
          return (
            <MenuItem
              key={`${index * (Math.random() * 999)}_option_${option.label}`}
              value={option.value}
              sx={{ fontSize: '0.9em', height: '38px' }}>
              <Typography sx={{ fontSize: '0.9em', paddingTop: '5px' }}>{option.label}</Typography>
            </MenuItem>
          );
        })}
      </CustomSelect>
    </FormControl>
  );
};
