import { ApolloClient, from, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { cache, isLoggedVar } from './cache';
import { typeDefs } from './schema';
import { brandUrls } from '../../../lib/vars';

const httpLink = createHttpLink({
  uri: brandUrls().gq,
});

// busca el id del dispositivo
const getDeviceId = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('tokenAuth') || '',
      deviceid: (await getDeviceId()) || '',
      client: 'ml.admin',
    },
  };
});

const errorHandler = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-unused-expressions
    graphQLErrors?.length &&
      graphQLErrors.forEach((err) => {
        const { code } = err.extensions;
        if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN')
          isLoggedVar({
            state: false,
            expired: true,
            message: 'Ha ocurrido un problema.',
            code: '500',
          });
        else if (code === 403) {
          localStorage.clear();
          isLoggedVar({
            state: false,
            expired: true,
            message: 'La sesión ha expirado.',
            code: code.toString(),
          });
        } else isLoggedVar({ ...isLoggedVar(), message: err.message, code: '' });
      });
  }
});

export const apolloClient = new ApolloClient({
  cache,
  link: from([errorHandler, authLink, httpLink]),
  typeDefs,
});
