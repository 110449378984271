import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { isLoggedVar } from './cache';
import { UPDATE_TOKEN } from './query';
import CustomAlert from '../common/alert';

const Auth = ({ children }: { children: any }) => {
  const [updateToken, { data, called }] = useMutation(UPDATE_TOKEN);
  const [isMount, setIsMount] = useState(false);
  const location = useLocation();
  // Actualiza el auth token del usuario por cada cambio de ventana
  const { data: dataLogged } = useQuery(gql`
    query IsUserLoggedIn {
      isLogged @client
    }
  `);

  // Verifica el token
  useEffect(() => {
    updateToken().catch(() => setIsMount(true));
  }, [updateToken]);

  // Respuesta de la verificación del token
  useEffect(() => {
    const res = data?.refreshUserPayrollToken;
    if (called && res) {
      setIsMount(true);
      if (res.tokenAuth) {
        localStorage.setItem('tokenAuth', res.tokenAuth);
        isLoggedVar({ state: true, expired: false, code: '', message: '' });
      } else {
        localStorage.clear();
        const response = localStorage.getItem('tokenAuth');
        isLoggedVar({
          state: false,
          expired: true,
          message: (typeof response === 'string' ? response : '') && 'La sesión ha expirado',
          code: '403',
        });
      }
    }
  }, [data, called]);

  useEffect(() => {
    const res = dataLogged.isLogged;
    if (res.message) {
      isLoggedVar({ ...isLoggedVar(), message: '' });
      if (res.code >= 500 && res.message !== 'No se ha encontrado ningún resultado.')
        <CustomAlert message={res.message} type="error" />;
      else if (
        res.code >= 400 &&
        res.code !== 403 &&
        res.message !== 'No se ha encontrado ningún resultado.'
      )
        <CustomAlert message={res.message} type="warning" />;
      else if (
        (res.code >= 300 || res.code === 403) &&
        res.message !== 'No se ha encontrado ningún resultado.'
      )
        <CustomAlert message={res.message} type="info" />;
      else if (res.code >= 200 && res.message !== 'No se ha encontrado ningún resultado.')
        <CustomAlert message={res.message} type="success" />;
    }
  }, [dataLogged.isLogged]);

  useEffect(() => {
    updateToken().catch(() => setIsMount(true));
  }, [location.pathname, updateToken]);

  return <>{children(dataLogged.isLogged, isMount)}</>;
};

export default Auth;
