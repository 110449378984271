import React from 'react';
import { Box, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { TStyleDefault } from '../../utils/types';

const styles: TStyleDefault = {
  boxMessage: {
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: '2em',
    textAlign: 'center',
    width: '50%',
    flexDirection: 'column',
  },
};

export const NoAuthorizeClient = () => {
  return (
    <Box
      style={{
        width: '100%',
        height: '98vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box sx={styles.boxMessage}>
        <>
          <LockIcon color="primary" sx={{ fontSize: '20rem' }} />
          <Typography style={{ fontSize: '40px' }}>
            Temporalmente fuera de servicio. Por favor, póngase en contacto con nuestro proveedor de
            servicios para obtener asistencia.
          </Typography>
        </>
      </Box>
    </Box>
  );
};
