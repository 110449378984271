export type TBrandsName =
  | 'local'
  | 'zali'
  | 'miliqa'
  | 'mili'
  | 'finnvo'
  | 'bancaliv'
  | 'creditoo'
  | 'inversiones'
  | 'fie'
  | 'fibanca'
  | 'fondo'
  | 'tusolucion'
  | 'credipress'
  | 'liberate'
  | 'nauw'
  | 'lenddi'
  | 'credinver'
  | 'freend'
  | 'gym'
  | 'maxcredit'
  | 'sofi'
  | 'libramoneda'
  | 'quickbank'
  | 'finanzas';

export const selectClientName: () => TBrandsName = () => {
  const client = window.location.hostname;
  if (client.includes('localhost')) {
    return 'local';
  }
  if (client.includes('inversiones')) {
    return 'inversiones';
  }
  if (client.includes('zali')) {
    return 'zali';
  }
  if (client.includes('qa2')) {
    return 'miliqa';
  }
  if (client.includes('mili')) {
    return 'mili';
  }
  if (client.includes('finnvo')) {
    return 'finnvo';
  }
  if (client.includes('bancaliv')) {
    return 'bancaliv';
  }
  if (client.includes('creditoo')) {
    return 'creditoo';
  }
  if (client.includes('fie')) {
    return 'fie';
  }
  if (client.includes('fibanca')) {
    return 'fibanca';
  }
  if (client.includes('elbancodelagente')) {
    return 'fondo';
  }
  if (client.includes('tusolucion')) {
    return 'tusolucion';
  }
  if (client.includes('credipress')) {
    return 'credipress';
  }
  if (client.includes('liberate')) {
    return 'liberate';
  }
  if (client.includes('nauwcolombia')) {
    return 'nauw';
  }
  if (client.includes('lenddi')) {
    return 'lenddi';
  }
  if (client.includes('credinver')) {
    return 'credinver';
  }
  if (client.includes('freend')) {
    return 'freend';
  }
  if (client.includes('gmserviciosfinancieros')) {
    return 'gym';
  }
  if (client.includes('maxcredit')) {
    return 'maxcredit';
  }
  if (client.includes('sofi')) {
    return 'sofi';
  }
  if (client.includes('libramoneda')) {
    return 'libramoneda';
  }
  if (client.includes('qbcol')) {
    return 'quickbank';
  }
  if (client.includes('finanzas')) {
    return 'finanzas';
  }

  return 'mili';
};
