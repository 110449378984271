import * as React from 'react';
import { Alert, AlertColor, Snackbar, Stack } from '@mui/material';

/* const WowAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); */

type TCustomAlert = {
  type: AlertColor;
  message: string;
  time?: number;
};

const CustomAlert: React.FC<TCustomAlert> = ({ type, message, time = 3000 }) => {
  const [open, setOpen] = React.useState(true);

  /* const handleClick = () => {
    setOpen(true);
  }; */

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={time}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <Alert
          onClose={handleClose}
          severity={type || 'success'}
          variant="filled"
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomAlert;
