import React from 'react';
import { Box } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import LockIcon from '@mui/icons-material/Lock';
import { TStyleDefault } from '../../utils/types';

const styles: TStyleDefault = {
  boxMessage: {
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const NoAuthorize: React.FC<{ validation: any }> = ({ validation }) => {
  return (
    <div style={{ width: '100%', height: '80%' }}>
      <Box sx={styles.boxMessage}>
        {validation?.uId && !validation?.loading ? (
          <>
            <LockIcon color="primary" sx={{ fontSize: '15rem' }} />
            <span>No tienes los permisos necesarios para ver esta página</span>
          </>
        ) : (
          <LockResetIcon color="primary" sx={{ fontSize: '15rem' }} />
        )}
      </Box>
    </div>
  );
};
