import { InMemoryCache, makeVar } from '@apollo/client';

export const isLoggedVar = makeVar({
  state: !!localStorage.getItem('tokenAuth'),
  expired: false,
  code: '',
  message: '',
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getOneCompanyId: {
          merge: true,
        },
        getMutualById: {
          merge: true,
        },
        getSellerCompanyById: {
          merge: true,
        },
        getCompanyManagerById: {
          merge: true,
        },
        getCompanyChamberCommerceById: {
          merge: true,
        },
        getBeneficiaryById: {
          merge: true,
        },
        getTypeAttributeById: {
          merge: true,
        },
        getPayrollCompanyById: {
          merge: true,
        },
        companyDocuments: {
          merge(existing = [], incoming = []) {
            const arrayOld = existing;
            let arrayNew = incoming;
            const data = [
              ...arrayOld.map((x: { cdId: string }) => {
                const findArray = arrayNew.find((y: { cdId: string }) => y.cdId === x.cdId);
                if (findArray) {
                  arrayNew = arrayNew.filter((y: { cdId: string }) => y.cdId !== x.cdId) || [];
                  return { ...x, ...findArray };
                }
                return x;
              }),
              ...arrayNew,
            ];
            return data;
          },
        },
        companyContacts: {
          merge(existing = [], incoming = []) {
            const arrayOld = existing;
            let arrayNew = incoming;
            const data = [
              ...arrayOld.map((x: { ccId: string }) => {
                const findArray = arrayNew.find((y: { ccId: string }) => y.ccId === x.ccId);
                if (findArray) {
                  arrayNew = arrayNew.filter((y: { ccId: string }) => y.ccId !== x.ccId) || [];
                  return { ...x, ...findArray };
                }
                return x;
              }),
              ...arrayNew,
            ];
            return data;
          },
        },
        companyCreditTypes: {
          merge(existing = [], incoming = []) {
            const arrayOld = existing;
            let arrayNew = incoming;
            const data = [
              ...arrayOld.map((x: { cctId: string }) => {
                const findArray = arrayNew.find((y: { cctId: string }) => y.cctId === x.cctId);
                if (findArray) {
                  arrayNew = arrayNew.filter((y: { cctId: string }) => y.cctId !== x.cctId) || [];
                  return { ...x, ...findArray };
                }
                return x;
              }),
              ...arrayNew,
            ];
            return data;
          },
        },
        lastSalesCompanies: {
          merge(existing = [], incoming = []) {
            const arrayOld = existing;
            let arrayNew = incoming;
            const data = [
              ...arrayOld.map((x: { lscId: string }) => {
                const findArray = arrayNew.find((y: { lscId: string }) => y.lscId === x.lscId);
                if (findArray) {
                  arrayNew = arrayNew.filter((y: { lscId: string }) => y.lscId !== x.lscId) || [];
                  return { ...x, ...findArray };
                }
                return x;
              }),
              ...arrayNew,
            ];
            return data;
          },
        },
        creditTypes: {
          merge(existing = [], incoming = []) {
            const arrayOld = existing;
            let arrayNew = incoming;
            const data = [
              ...arrayOld.map((x: { ctId: string }) => {
                const findArray = arrayNew.find((y: { ctId: string }) => y.ctId === x.ctId);
                if (findArray) {
                  arrayNew = arrayNew.filter((y: { ctId: string }) => y.ctId !== x.ctId) || [];
                  return { ...x, ...findArray };
                }
                return x;
              }),
              ...arrayNew,
            ];
            return data;
          },
        },
        isLogged: {
          read: () => isLoggedVar(),
        },
      },
    },
  },
});
