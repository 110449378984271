import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Control, useController } from 'react-hook-form';
import validator from '../../../../lib/validator';

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '1em',
  },
  '& .MuiInputBase-input': {
    fontSize: '1em',
  },
  // '& .MuiAutocomplete-root': {
  //   fontSize: '1em',
  //   // Estilos para el contenedor del Autocomplete
  //   // marginBottom: theme.spacing(1), // Ajustar el espacio inferior según tus necesidades
  // },

  '& .MuiAutocomplete-listbox': {
    // Estilos para el contenedor de la lista desplegable
    backgroundColor: 'lightgray', // Cambiar el color de fondo de la lista
  },
  '& .MuiAutocomplete-option': {
    // Estilos para los elementos de la lista desplegable
    fontSize: '1em', // Cambiar el tamaño del texto de los elementos
    '&[data-focus="true"]': {
      // Estilos para el elemento resaltado (en foco)
      backgroundColor: 'blue', // Cambiar el color de fondo del elemento resaltado
      color: 'white', // Cambiar el color del texto del elemento resaltado
    },
  },
}));

const CustomTextField = styled(TextField)(({ theme: any }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '1em',
  },
  '& .MuiInputBase-input': {
    fontSize: '1em',
  },
  '& .MuiInputBase-root': {
    fontSize: '1em',
    height: '38px',
  },
}));

export const AutoCompleteAdapter = (props: {
  control: Control;
  schema: validator.AnySchema;
  name: string;
  keyValue?: string;
  keyLabel: string;
  options?: any[];
  multiple?: boolean;
  label: string;
}) => {
  const { name, control, schema, options, keyLabel, keyValue, multiple, label } = props;
  const {
    field,
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });
  const theme = useTheme();
  return (
    <CustomAutocomplete
      options={options || []}
      multiple={multiple}
      getOptionLabel={(option: any) => {
        /* return keyValue
          ? options?.find((opt: any) => opt[keyValue] === option)?.[keyLabel]
          : option[keyLabel]; */
        return option[keyLabel];
      }}
      onChange={(_, value: any) => {
        if (!multiple) return field.onChange(keyValue ? value[keyValue as any] : value);
        return field.onChange(keyValue ? value?.map((x: any) => x?.[keyValue]) : value);
      }}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.value === value.value;
      }}
      /* value={field.value} */
      onBlur={field.onBlur}
      size="small"
      renderInput={({ InputLabelProps, ...rest }) => {
        return (
          <CustomTextField
            {...rest}
            variant="outlined"
            label={label}
            name={field.name}
            inputRef={field.ref}
            error={invalid}
            helperText={err?.message}
            sx={{ mb: theme.spacing(1), fontSize: '1em' }}
          />
        );
      }}
    />
  );
};
