/* eslint-disable no-unused-vars */
import React from 'react';
import { Helmet } from 'react-helmet';
import { TBrandsName, selectClientName } from '../../../../common/utils';
import image from '../images';

const titles = {
  bancaliv: 'Bancaliv - Portal',
  credipress: 'Credipress - Portal',
  creditoo: 'Creditoo - Portal',
  fibanca: 'Fibanca - Portal',
  finnvo: 'Finnvo - Portal',
  fondo: 'Fondo - Portal',
  tusolucion: 'Tu Solución - Portal',
  mili: 'Mili - Portal',
  miliqa: 'Mili QA - Portal',
  local: 'Mili - Portal',
  liberate: 'Liberatech - Portal',
  nauw: 'Nauw - Portal',
  fie: 'Fie - Portal',
  lenddi: 'Lenddi - Portal',
  gym: 'G&M - Portal',
  inversiones: 'Inversiones - Portal',
  credinver: 'Credinver - Portal',
  freend: 'Freend - Portal',
  maxcredit: 'Maxcredit - Portal',
  zali: 'Zali - Portal',
};

const favicons = {
  bancaliv: '/favicon/bancaliv/favicon.ico',
  credipress: '/favicon/credipress/favicon.ico',
  creditoo: '/favicon/creditoo/favicon.ico',
  fibanca: '/favicon/fibanca/favicon.ico',
  finnvo: '/favicon/finnvo/favicon.ico',
  fondo: '/favicon/fondoGente/favicon.ico',
  tusolucion: '/favicon/tusolucion/favicon.ico',
  local: '/favicon/mili/favicon.ico',
  mili: '/favicon/mili/favicon.ico',
  miliqa: '/favicon/mili/favicon.ico',
  liberate: '/favicon/liberate/favicon.ico',
  nauw: '/favicon/nauw/favicon.ico',
  fie: '/favicon/fie/favicon.ico',
  lenddi: '/favicon/lenddi/favicon.ico',
  gym: '/favicon/gym/favicon.ico',
  credinver: '/favicon/credinver/favicon.ico',
  inversiones: '/favicon/inversiones/favicon.ico',
  freend: '/favicon/freend/favicon.ico',
  maxcredit: '/favicon/maxcredit/favicon.ico',
  zali: '/favicon/zali/favicon.ico',
};

const brands = {
  bancaliv: 'Bancaliv',
  credipress: 'Credipress',
  creditoo: 'Creditoo',
  fibanca: 'Fibanca',
  finnvo: 'Finnvo',
  fondo: 'Fondo',
  mili: 'Mili',
  miliqa: 'Mili QA',
  local: 'Mili',
  tusolucion: 'Tu Solución',
  liberate: 'Liberate',
  nauw: 'Nauw',
  fie: 'Fie',
  lenddi: 'Lenddi',
  gym: 'G&M',
  credinver: 'Credinver',
  inversiones: 'Inversiones & negocios',
  freend: 'freend',
  maxcredit: 'Maxcredit',
  zali: 'Zali',
};

const descriptions = {
  mili: 'Mili, tu crédito de confianza',
  miliqa: 'Mili QA, tu crédito de confianza',
  local: 'Mili, tu crédito de confianza',
  bancaliv: 'Bancaliv, Pensando en ti',
  credipress: 'Credipress, tu crédito 100% digital',
  finnvo: 'Finnvo, tu crédito de libranza',
  fibanca: 'Fibanca, Financiamiento de banca',
  fondo: 'Fondo de la gente',
  creditoo: 'Creditoo, Fábrica de crédito SAS',
  tusolucion: 'Tu solución, avanzamos contigo',
  liberate: 'Liberateco',
  nauw: 'Nauw, Siempre contigo',
  fie: 'Fie, Financiamos tus sueños',
  lenddi: 'Lenddi, Luz verde para tu crédito',
  gym: 'G&M, Servicios financieros S.A.S',
  credinver: 'Credinver',
  inversiones: 'Inversiones & negocios S.A',
  freend: 'Freend S.A',
  maxcredit: 'Maxcredit',
  zali: 'Zali',
};

export const MetaPortal = ({ cli }: { cli?: TBrandsName }) => {
  const client = cli ?? selectClientName();
  const fav = image('logoM');
  const title = client ? titles[client] : titles.mili;
  const brand = client ? brands[client] : brands.mili;
  const description = client ? descriptions[client] : descriptions.mili;
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={fav} />
      <meta name="description" content={description} />
      <meta property="og:site_name" id="brandExist" content={brand} />
    </Helmet>
  );
};
